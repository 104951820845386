<template>
  <div v-if="!loading">
    <h1 class="main-header">
      <translate translate-context="tag" :translate-params="{id: tag.id, name: tag.code}">Edit tag #%{id} - %{code}
      </translate>
    </h1>
    <TagForm :data="tag" @save="onSave" :save_text="$pgettext('form','Save')"/>
  </div>
</template>

<script>
import {mapActions, mapGetters} from "vuex";
import {RecordNotFound, SuccessNotification} from "@/domain/core/exception/exceptions";
import TagForm from "@/domain/tag/components/TagForm.vue";

export default {
  name: 'EditTag',
  components: {TagForm},
  data() {
    return {
      loading: true,
    }
  },
  async mounted() {
    try {
      await this.fetchTag(this.$route.params.id)
    } catch (e) {
      if (e instanceof RecordNotFound) {
        await this.$router.push({name: 'tag.list'})
      }
    }
    this.loading = false
  },
  computed: {
    ...mapGetters('tag', [
      'tag',
    ]),
  },
  methods: {
    ...mapActions('tag', [
      'fetchTag',
      'updateTag',
    ]),
    async onSave(data) {
      await this.updateTag({id: this.tag.id, data: data})
      await this.$router.push({name: 'tag.list', category: this.$route.params.category})
      throw new SuccessNotification(this.$pgettext('tag', 'Tag updated!'))
    }
  }
}
</script>

<style scoped>

</style>